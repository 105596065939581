<template>
  <div id="loading">
    <v-progress-circular indeterminate/>  
  </div>
</template>

<script>
import firebase from 'firebase/app'
import Auth from '../firebase/auth'


export default {
  created () {    
    if (!window.sessionStorage.getItem('pending')) {
      window.sessionStorage.setItem('pending', 1);
      Auth.login()
    }
  },
  computed: {

  },
  mounted () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        window.sessionStorage.removeItem('pending');
        firebase.auth()
        .getRedirectResult()
        .then((result) => {
          this.$store.dispatch('users/setTwitterData', result.additionalUserInfo)
        }).then(()=>{
          this.$router.push('/_u/' + user.uid)
        })
      }
    })
  },
  methods: {
  }
}
</script>

<style lang="scss">
  #loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index:9999;
    position: fixed;
    background-color: rgba(#000, 0.5);
  }
</style>