<template>
  <div>
    <div style="height:40px;"></div>
    <v-container style="max-width:640px">
      <v-row justify="center">
        <v-avatar size="128">
          <v-img
            :src="img" 
          >
          </v-img>
        </v-avatar>
      </v-row>
      <v-row justify="center">
          <div
            class="text-h5"
          >
            {{this.$store.getters.user.displayName}}          

          </div>
          <ShareNetwork
            network="twitter"
            :url="this.myPageUrl"
            :title="this.tweetText"
            hashtags="つづいったー"
            style="text-decoration: none;"
          >
            <v-icon color="blue" >
              mdi-twitter
            </v-icon>
          </ShareNetwork>
      </v-row>
      <v-row justify="center">
        <div
          class="text-h7"
        >          
        </div>
      </v-row>
      <v-row justify="center">
        <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              {{postedIcon}}
            </v-icon>
          </v-card>
        </v-col>
        <!-- <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              mdi-calendar-arrow-right
            </v-icon>
            <br>
          </v-card>
        </v-col> -->
        <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              mdi-calendar-arrow-right
            </v-icon>
            <br>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0" justify="center">
        <v-col class="pt-0" col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <p class="font-weight-black text-h6">
              総投稿数 : {{drawingSum}}
            </p>
          </v-card>
        </v-col>
        <!-- <v-col class="pt-0" col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            最大連続投稿日数 : {{updateSum}} 日
          </v-card>
        </v-col> -->
        <v-col class="pt-0" col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <p class="font-weight-black text-h6">
              現在の連続更新日数 : {{updateNow}} 日
            </p>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row class="my-0 py-0" justify="center">
        <v-col><Calender @clickDateCol="setViewDate" /></v-col>
      </v-row>

      <v-divider class="p-2"></v-divider>
      <v-row justify="center">
        <v-col v-if="drawingContentList.length != 0">
          <div v-for="drawingContent in drawingContentList" :key="drawingContent.id">
            <v-row style="cursor:default;">
              <v-col>
                <DrawingConents 
                  :id="drawingContent.id"
                  :isPublished="drawingContent.isPublished"
                  :caption="drawingContent.caption"
                  :imageUrl="drawingContent.imageUrl"
                  :title="drawingContent.title" 
                  :createdTime="drawingContent.updateAt"
                  :isMypage="true"
                />
              </v-col>
            </v-row>
            <v-divider
              class="mx-4"
            ></v-divider>
          </div>
        </v-col>
        <v-col v-else class="centering" >
          <v-container>
            <v-row >
              <v-col class="centering">
                <div>
                  {{ this.viewDate + "の投稿数は0件です。" }}
                </div>
              </v-col>
            </v-row>
            <v-row >
              <v-col v-if="this.isToday( this.viewDate )" class="centering" >
                <div>
                  継続は力なり！
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="this.isToday( this.viewDate )" class="centering">
               <v-btn>投稿する！</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import { mdiTrophyOutline } from '@mdi/js';
import { mdiCommentCheckOutline } from '@mdi/js'
import { mdiTextBoxCheckOutline } from '@mdi/js';

import DrawingConents from '@/components/DrawingContents.vue'

import Calender from '@/components/Calender.vue'

import calenderUtil from '@/lib/calender.js'
import agentUtil from '@/lib/agentUtil.js'

import auth from '../firebase/auth'

export default {
    data() {
      return {
        viewDate: '',
        checkIcon: mdiTextBoxCheckOutline,
        postedIcon :mdiCommentCheckOutline,
        trophy: mdiTrophyOutline,
        
        
      }
    },
    methods:{
      setViewDate(date){
        this.viewDate = calenderUtil.getYmd(date)
      },
      tweetMypage(){
        //シェアする画面を設定
        
        //シェア用の画面へ移行
          var shareURL =''
        if( !agentUtil.isMobile() ){
          shareURL ='https://twitter.com/intent/tweet?text=' + this.myPageUrl + '/' + new Date().getTime()
          const option = 'status=1,width=600,height=200,top=100,left=100'
          window.open(shareURL, 'twitter', option)

        } else {
          shareURL = 'twitter://post?message=' + this.myPageUrl + '/' + new Date().getTime()
          const link = document.createElement('a')
          link.href = shareURL
          link.click()
        }
      },
      isToday(date){
        return calenderUtil.isToday(new Date(date))
      },

    },    
    computed:{
      tweetText(){
        return ''
      },
      myPageUrl(){
        return "https://tuzitter.com/u/" + auth.getCurrentUser().uid +'?' + new Date().getTime()
        //return "https://tuzitter.com/u/" + auth.getCurrentUser().uid +'/ver?' + new Date().getTime()
      },
      isMobile(){
        return agentUtil.isMobile() 
      },
      img(){
        if ( this.$store.getters['users/twitterIconURL'] == "" ){
          return ""
        }else{
          return this.$store.getters['users/twitterIconURL'].replace("_normal", "")
        }
      },
      drawingContentList(){
        return this.$store.getters['drawingContents/getDrawingContents'].filter((a) => {
          return a.updateYmd == this.viewDate
        }).sort((a, b) => {
        if (a.createAt > b.createAt) {
          return -1
        } else {
          return 1
        }
      })
      },
      drawingSum(){
        return this.$store.getters['drawingContents/getDrawingContents'].length
      },
      updateSum(){
        return this.$store.getters['drawingContents/getMaxUpdateDays']
      },
      updateNow(){
        return this.$store.getters['drawingContents/getUpdateDays']
      }
    },
    created() {
      this.viewDate = calenderUtil.getYmd()
    },
    components:{
        DrawingConents,
        Calender,
    }
}
</script>

<style >
.calendar-day-class {
  border: 1px solid #9999DD !important;
  border-radius:3px !important;
}

.calendar-day-done-class {
  border: 1px solid #9999DD !important;
  border-radius:3px !important;
  color: #ffffff;
}

.calendar-day-done-content-class {
  background-color: #c24e4e;
  border-radius: 3px !important;
}

</style>