<template>
  <v-container style="max-width:640px">
    <v-row justify="center">
        <v-col>
            <v-text-field label="タグ名"></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-btn>追加</v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
export default {
    
}
</script>