<template>
<div>
    <div style="height:40px;"></div>
    <v-container style="max-width:640px">
      <v-row justify="center">
        <v-avatar size="128">
          <v-img
            :src="img" 
          >
          </v-img>
        </v-avatar>
      </v-row>
      <v-row justify="center">
        <div
          class="text-h5"
        >
          {{this.user.twitterName}}
        
        </div>
      </v-row>
      <v-row justify="center">
        <div
          class="text-h7"
        >          
        </div>
      </v-row>
      <v-row justify="center">
        <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              {{postedIcon}}
            </v-icon>
          </v-card>
        </v-col>
        <!-- <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              mdi-calendar-arrow-right
            </v-icon>
            <br>
          </v-card>
        </v-col> -->
        <v-col col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            <v-icon  size="64">
              mdi-calendar-arrow-right
            </v-icon>
            <br>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-0" justify="center">
        <v-col class="pt-0" col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            総投稿数 : {{drawingSum}}
          </v-card>
        </v-col>
        <v-col class="pt-0" col="1">
          <v-card  elevation="0" class="d-flex justify-center">
            現在の連続更新日数 : {{updateNow}} 日
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col><Calender :drawingNumAtDate="drawingContentsListDateArray" @clickDateCol="setViewDate" /></v-col>
      </v-row>
      <v-row style="height:20px">
      </v-row>
      <v-divider class="p-2"></v-divider>
      <v-row >
        <v-col v-if="drawingContentListByDate.length != 0 " >
          <div v-for="drawingContent in drawingContentListByDate" :key="drawingContent.id">
            <v-row style="cursor:default;">
              <v-col>
                <DrawingConents 
                  :id="drawingContent.id"
                  :isPublished="drawingContent.isPublished"
                  :caption="drawingContent.caption"
                  :imageUrl="drawingContent.imageUrl"
                  :title="drawingContent.title" 
                  :createdTime="drawingContent.updateAt"
                />
              </v-col>
            </v-row>
            <v-divider
              class="mx-4"
            ></v-divider>
          </div>
        </v-col>
        <v-col v-else >
          {{ this.viewDate + "の投稿数は0件です。" }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mdiTrophyOutline } from '@mdi/js';
import { mdiCommentCheckOutline } from '@mdi/js'
import { mdiTextBoxCheckOutline } from '@mdi/js';

import firestore from '@/firebase/firestore'
import Calender from '@/components/Calender.vue'
import calenderUtil from '@/lib/calender.js'

import { firebase } from '@/firebase/init'
import DrawingConents from '@/components/DrawingContents.vue'


export default {
    data() {
      return {
        uid: this.$route.params.uid,
        user:{},
        drawingContentList: [],
        //日付ごとに
        drawingContentsListDateArray: {},
        updateSum : 0,
        updateNow : 0,
        viewDate: '',
        checkIcon: mdiTextBoxCheckOutline,
        postedIcon :mdiCommentCheckOutline,
        trophy: mdiTrophyOutline,
      }
    },
    methods:{
      setViewDate(date){
        this.viewDate = calenderUtil.getYmd(date)
      },
      async getDrawingContent(uid) {
        await firestore
         .collection('drawingContents')
         .where('uid', '==', uid)
         .where('isPublished', '==', 0)
         .get()
         .then((querySnapShot) => {
           
           querySnapShot.forEach((element)=>{
             const data = element.data()

              this.drawingContentList.push({
                id: element.id,
                uid: data.uid,
                title: data.title,
                tagId: data.tagId,
                imageUrl: data.imageUrl,
                caption: data.caption,
                isPublished: data.isPublished,
                updateAt: data.updateAt,
                updateYmd: data.updateYmd,
                createAt: data.createAt,
                createYmd: data.createYmd,
              })

            })
         })
         firestore
                .collection('drawingContentsDate')
                .doc(uid).get().then((item)=>{
                  this.updateSum = calenderUtil.calcMaxUpdateDays(item.data().calender).maxDateNum
                  this.updateNow = calenderUtil.calcUpdateDays(item.data().calender).maxDateNum
                  this.drawingContentsListDateArray = item.data().calender
                })
         
          
         this.drawingContentList.forEach(element =>{
          firebase
            .storage()
            .ref()
            .child(element.imageUrl)
            .getDownloadURL().then(( imageUrl )=>{
              element.imageUrl = imageUrl
            })
        })
      },
      getUser(uid) {
        const usersRef = firestore.collection('users').doc(uid)
        usersRef.get().then((doc) => {
          this.user = {      
            twitterName: doc.data().twitterName,
            twitterIconURL: doc.data().twitterIconURL,
          }
        })
      }
    },

    computed:{
      drawingContentListByDate(){
        return this.drawingContentList.filter((a) => {
          return a.updateYmd == this.viewDate
        })
      },
      img(){
        if( typeof this.user.twitterIconURL == "undefined"){
          return ''
        }
        return this.user.twitterIconURL.replace("_normal", "")
      },

      drawingSum(){
        return this.drawingContentList.length
      },
    },
    created() {
      this.viewDate = calenderUtil.getYmd()
      this.getUser(this.uid)
      this.getDrawingContent(this.uid)
    },
    components:{
        DrawingConents,
        Calender,
    }
}
</script>

<style >
.calendar-day-class {
  border: 1px solid #9999DD !important;
  border-radius:3px !important;
}

.calendar-day-done-class {
  border: 1px solid #9999DD !important;
  border-radius:3px !important;
  color: #ffffff;
}

.calendar-day-done-content-class {
  background-color: #c24e4e;
  border-radius: 3px !important;
}

</style>