<template>
  <div>
    <v-divider></v-divider>
    <v-container style="max-width:640px">
      <div style="height:20px"></div>
      <v-row class="pb-0">
        <v-col class="py-1" cols="9">
          <v-select
           hide-details
            v-model="id"
            item-text="tagName"
            item-value="id"
            :items="tagItems"
          />
        </v-col >
        <v-col class="py-1" cols="3">
          <v-select
            hide-details
            v-model="sortVal"
            :items="sortObj"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="sortedDrawingContentList.length != 0">
          <div v-for="drawingContent in sortedDrawingContentList" :key="drawingContent.id" >
            <v-row style="display:block">
              <v-col>
                <DrawingConents
                  :id="drawingContent.id"
                  :isPublished="drawingContent.isPublished"
                  :caption="drawingContent.caption"
                  :imageUrl="drawingContent.imageUrl"
                  :title="drawingContent.title"
                  :createdTime="drawingContent.updateAt"
                />
              </v-col>
              <v-divider></v-divider>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import DrawingConents from '@/components/DrawingContents.vue'

import firestore from '@/firebase/firestore'


export default {
  name:"PostingContentsList",
  data () {
    return {
      id:0,
      uid: this.$route.params.uid,
      drawingContentList: [],
        //日付ごとに
      drawingContentsListDateArray: {},
      tagItems: [{ id: 0, tagName: '指定なし' }],
      sortVal:0,
      sortObj:[
        {
          value:0,
          text:'新しい順'
        },
        {
          value:1,
          text:'古い順'
        }
      ]
    }
  },  
  components:{
    DrawingConents
  },
  computed:{
    sortedDrawingContentList(){
      if(this.id == 0 ){
        return this.sortContents(this.drawingContentList)
      }
      return this.sortContents(this.getDrawingContentsByTagId(this.id))
    }
  },
  created(){
    this.getDrawingContent(this.uid)
    this.getTag(this.uid)
  },
  methods:{
    getTag(uid){
      if( !this.getTagItem(uid).length ){
        return [{id:0,tagName:'指定なし'}]
      }else{
        return this.getTagItem(uid)
      }
    },
    getDrawingContentsByTagId(id) {
      return this.drawingContentList.filter((a) => {
        return a.tagId == id
      })
    },
      async getDrawingContent(uid) {
        await firestore
         .collection('drawingContents')
         .where('uid', '==', uid)
         .where('isPublished', '==', 0)
         .get()
         .then((querySnapShot) => {
            querySnapShot.forEach((element)=>{
              const data = element.data()
              this.drawingContentList.push({
                id: element.id,
                uid: data.uid,
                title: data.title,
                tagId: data.tagId,
                imageUrl: data.imageUrl,
                caption: data.caption,
                isPublished: data.isPublished,
                updateAt: data.updateAt,
                updateYmd: data.updateYmd,
                createAt: data.createAt,
                createYmd: data.createYmd,
              })

              if (
                !Array.isArray(this.drawingContentsListDateArray[data.createYmd])
              ) {
                this.drawingContentsListDateArray[data.createYmd] = []
              }
              this.drawingContentsListDateArray[data.createYmd].push({
                id: element.id,
                uid: data.uid,
                title: data.title,
                tagId: data.tagId,
                imageUrl: data.imageUrl,
                caption: data.caption,
                isPublished: data.isPublished,
                updateAt: data.updateAt,
                updateYmd: data.updateYmd,
                createAt: data.createAt,
                createYmd: data.createYmd,
              })
            })
         })
      },
      async getDrawingContentByTagId(uid,tagId) {
        await firestore
         .collection('drawingContents')
         .where('uid', '==', uid)
         .where('tagId', '==', tagId)
         .where('isPublished', '==', 0)
         .get()
         .then((querySnapShot) => {
            querySnapShot.forEach((element)=>{
              const data = element.data()
              this.drawingContentList.push({
                id: element.id,
                uid: data.uid,
                title: data.title,
                tagId: data.tagId,
                imageUrl: data.imageUrl,
                caption: data.caption,
                isPublished: data.isPublished,
                updateAt: data.updateAt,
                updateYmd: data.updateYmd,
                createAt: data.createAt,
                createYmd: data.createYmd,
              })

              if (
                !Array.isArray(this.drawingContentsListDateArray[data.createYmd])
              ) {
                this.drawingContentsListDateArray[data.createYmd] = []
              }
              this.drawingContentsListDateArray[data.createYmd].push({
                id: element.id,
                uid: data.uid,
                title: data.title,
                tagId: data.tagId,
                imageUrl: data.imageUrl,
                caption: data.caption,
                isPublished: data.isPublished,
                updateAt: data.updateAt,
                updateYmd: data.updateYmd,
                createAt: data.createAt,
                createYmd: data.createYmd,
              })
            })
         })
      },
      async getTagItem(uid) {
      await firestore
        .collection('tags')
        .where('uid', '==', uid)
        .get()
        .then((querySnapShot) => {
          querySnapShot.forEach((element) => {
            const data = element.data()
            this.tagItems.push({
              id: element.id,
              uid: data.uid,
              tagName: data.tagName,
              createAt: data.createAt,
              updateAt: data.updateAt,
            })
          })
        })
    },
    clearSelectedTag(){
      this.id = 0
    },
    sortContents(list){
      if( this.sortVal == 0){
        return list.sort((a,b)=>{ 
          if (new Date (a.createAt) > new Date (b.createAt)) {
            return -1
          } else {
            return 1
          }
        })
      }

      if( this.sortVal == 1){
        return list.sort((a,b)=>{ 
          if (new Date(a.createAt) < new Date (b.createAt)) {
            return -1
          } else {
            return 1
          }
        })
      }
    }
  },
}
</script>

<style scoped>
  .tab{
     border-radius: 5px !important; 
    border: 1px solid #9999DD !important;
  }
</style>