import fb from 'firebase/app'

const firebaseConfig = {
  //credential: admin.credential.applicationDefault(),
  apiKey: 'AIzaSyCrMxRq53Xea6tPDr9pqIv_nt12D2ns3tk',
  authDomain: 'netabo-b87a7.firebaseapp.com',
  databaseURL: 'https://netabo-b87a7-default-rtdb.firebaseio.com',
  projectId: 'netabo-b87a7',
  storageBucket: 'netabo-b87a7.appspot.com',
  messagingSenderId: '872210957231',
  appId: '1:872210957231:web:1d1d2c50654b76b1afe55a',
  measurementId: 'G-D0TPK4SDQ7',
}

// 初期化が終わっていればインスタンスを返す
export const firebase = !fb.apps.length
  ? fb.initializeApp(firebaseConfig)
  : fb.app()
