<template>
  <v-form ref="posting_form" class="pt-9">
    <v-container class="posting-form" style="max-width:640px">
        <v-row  v-if="!isMobile()" justify="center" class="pb-6">
          <FileUpload 
            :dropAreaHeight="dropAreaHeight"
            :dropAreaWidth="dropAreaWidth"
            :imageHeight="imageHeight"
            :imageWidth="imageWidth"
            @getFile='getFile($event)'
            @clearImg="clearImg()"
          />
        </v-row>
        <v-row v-else>
          <FileUploadMobile @getFile='getFile($event)'/>
        </v-row>
        <small v-if="sendImgEmpty" style="color:red;">画像を選択してください</small>
        <div style="height:30px;"/>
        <v-row class="mt-3 py-2">
            <v-col style="height:70px;">
              <v-text-field 
                label="タイトル"
                :rules="[limit_length_title,limit_length_title_min]"
                counter="30"
                v-model="title"
              >
              </v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-2">
          <v-col style="height:70px;">
            <v-select
              label="タグ"
              v-model="tagSelectedItems"
              @change="fetchTagVal"
              :items="this.$store.getters['tags/tagItems']"
              item-text="tagName" item-value="id"
              outlined
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="py-0" justify="end">
          <v-spacer></v-spacer>
          <v-col class="py-0" cols="4" xs="3" sm="3">
            <TagAddDialog @addTagSelectedItems="addTagSelectedItems($event)">
              <template v-slot:button>
                <v-btn  elevation="0" color="#FFFFFF"><a>タグを追加...</a></v-btn>
              </template>
            </TagAddDialog>
          </v-col>
        </v-row>
        <v-row class="py-1">
          <v-col style="height:150px;">
            <v-textarea
              v-model="caption"
              label="キャプション"
              no-resize
              class="mix-height:30px"
              outlined
              persistent-hint
              :rules="[limit_length_caption]"
              counter="200"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col>
            <v-radio-group v-model="isPublished" row >
              <v-spacer></v-spacer>
              <template v-slot:label >
                <div><strong>公開設定</strong></div>
              </template>
              <v-radio >
                <template v-slot:label>
                  <div>公開</div>
                </template>
              </v-radio>
              <v-radio>
                <template v-slot:label>
                  <div>非公開</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="mt-9">
          <v-btn color="cyan lighten-2" block @click="postContent">
            投稿する！
          </v-btn>
        </v-row>
        <TweetDialog :display="posted" :text="tweetText"/>
    </v-container>
  </v-form>
</template>

<script>
import auth from '@/firebase/auth' 
import router from '@/router/' 

import calenderUtil from '@/lib/calender.js'

import FileUpload from '@/components/FileUpload.vue'
import FileUploadMobile from '@/components/FileUploadMobile.vue'
import agentUtil from '@/lib/agentUtil.js'
import calender from '@/lib/calender.js'
import TagAddDialog from '@/components/TagAddDialog.vue'
import TweetDialog from '@/components/TweetDialog.vue'

import firestore from '@/firebase/firestore'
import { firebase } from '@/firebase/init'

export default {
   name:"PostingForm",
   data: () => ({
      sendImgEmpty: false,
      uploadFile: '',
      profuctionTimeInputflg:false,
      title:new Date().toLocaleDateString(),
      caption:'',
      expand: false,
      label:'タグを追加...',
      productionHours:0,
      productionMinutes:0,
      modifyImgFlg: true,
      isPublished: 0,
      tagId:0,
      dropAreaHeight: '300px',
      dropAreaWidth:'600px',
      imageHeight:'200px',
      imageWidth:'500px',
      tagSelectedItems:{
        id: 0,
        tagName: '指定なし'
      },
      tagItems:[],
      posted: false,
      tweetText:'',
      limit_length_title: value => value.length <= 30 || "30文字以内で入力してください" ,// 文字数の制約
      limit_length_title_min: value => value.length > 0 || "1文字以上で入力してください" ,// 文字数の制約
      limit_length_caption: value => value.length <= 200 || "200文字以内で入力してください" // 文字数の制約
    }),
    methods: {
    isMobile(){
      return agentUtil.isMobile()
    },
    clearImg(){
      this.uploadFile = ''
    },
    addTagSelectedItems(e){
      this.tagSelectedItems = e;
      this.tagId = e.id
    },
    fetchTagVal(e){
      this.tagId = e
    },
    getFile( $event ){
      this.uploadFile = $event
      this.sendImgEmpty = false
    },
    getTag(){
      this.tagItems = this.$store.getters['tags/tagItems']
      this.tagItems.unshift({id: 0, tagName: '指定なし'})
    },
    postContent(){
      
      if(!this.$refs.posting_form.validate()){
        return ;
      }
      
      if(this.uploadFile ==''){
        this.sendImgEmpty = true
        return ;
      }

      //開始
      this.$store.commit('startLoading')
      const ymd = calenderUtil.getYmd()
      const dateTime = calenderUtil.getDateTime()
      let postingItem = {
        title : this.title,
        tagId : this.tagId,
        caption : this.caption,
        isPublished : this.isPublished,
        createYmd: ymd,
        createAt: dateTime,
        updateYmd: ymd, 
        updateAt: dateTime, 
      }

      if(postingItem.title == ''){
        postingItem.title = '無題'
      }

      // 画像のアップロード
      const docRef = firestore.collection('drawingContents').doc()

      const storageRef = firebase.storage().ref()
      const filePath = '/img/' + firebase.auth().currentUser.uid + '/' + docRef.id + '/' + 0 
      
      storageRef
      .child( filePath )
      .put( this.uploadFile )
      .then( ( snapshot ) => {
        snapshot.ref.updateMetadata(
        {
          customMetadata: {
            'isPublished': postingItem.isPublished,
          }
        })

        // 画像アップロードに成功したらfirestoreに記録
        postingItem.imageUrl = filePath
          this.$store.dispatch('drawingContents/addDrawingContent', {postingItem,docRef}).then(()=>{
            calender.calcMaxUpdateDays( this.$store.getters['drawingContents/getDrawingContentsDateArray'])
            calender.calcUpdateDays( this.$store.getters['drawingContents/getDrawingContentsDateArray'] )
        }).then(()=>{
          this.$store.commit('endLoading')
          // todo 
          // this.posted = true
          // this.tweetText = "今日も絵をかきました！" + this.$store.getters['drawingContents/getUpdateDays'] + "日連続で更新中 https://tuzitter.com @OekakiTuzitter"
            router.push({ path: `/_u/${auth.getCurrentUser().uid}` })
        })
      })
      .catch((e)=>{
        console.log(e)
      })
    }
  },
  components:{
    FileUpload,
    FileUploadMobile,
    TagAddDialog,
    TweetDialog,
  },
}
</script>

<style>
.right-input input {
  text-align: right;
}

.posting-form>.row{
  justify-content: center;
}

.col>p{
  margin-bottom: 0px;
}

.col-header{
  display: flex;  flex-direction: column;  justify-content: center;  align-items: center;
}
</style>