export default {
  getYmd(dateTime = new Date()) {
    const month = dateTime.getMonth() + 1
    return dateTime.getFullYear() + '/' + month + '/' + dateTime.getDate()
  },
  getYm(dateTime = new Date()) {
    const month = dateTime.getMonth() + 1
    return dateTime.getFullYear() + '' + month
  },
  getDateTime() {
    return new Date().toLocaleString()
  },
  isToday(someDate) {
    const today = new Date()
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    )
  },
  calcMaxUpdateDays(dateObject) {
    if (dateObject == typeof 'undefined') return
    //配列に変換
    let dateArray = Object.keys(dateObject)
    //日付順に並び替え
    dateArray.sort((a, b) => new Date(a) - new Date(b))
    let maxDateNum = 1
    let countDateNum = 1
    let maxDate = ''
    let targetDate = ''
    let beforeDate = ''

    if (dateArray.length == 0) return { maxDate: '', maxDateNum: 0 }

    dateArray.forEach((element) => {
      targetDate = new Date(element)
      if (beforeDate == '') {
        //投稿初日
        beforeDate = targetDate
        return
      }
      //前回投稿日の次の日を作成
      const checkedDate = new Date(beforeDate.setDate(beforeDate.getDate() + 1))
      if (checkedDate.getTime() == targetDate.getTime()) {
        //前回登校日とチェック中の登校日が連続した日付なら1足す
        countDateNum = countDateNum + 1
        if (maxDateNum < countDateNum) {
          maxDate = new Date(
            beforeDate.setDate(beforeDate.getDate())
          ).toLocaleDateString()
          maxDateNum = countDateNum
        }
      } else {
        //連続が途切れた場合
        if (maxDateNum < countDateNum) {
          // カウント中が最大値
          maxDate = new Date(
            beforeDate.setDate(beforeDate.getDate())
          ).toLocaleDateString()
          maxDateNum = countDateNum
        }
        countDateNum = 1
      }

      beforeDate = targetDate
    })
    return { maxDate, maxDateNum }
  },
  calcUpdateDays(dateObject) {
    if (dateObject == typeof 'undefined') return
    //配列に変換
    let dateArray = Object.keys(dateObject)
    //日付順に並び替え
    dateArray.sort((a, b) => new Date(b) - new Date(a))
    let maxDateNum = 1
    let countDateNum = 1
    let maxDate = ''
    let targetDate = ''
    let afterDate = ''

    if (dateArray.length == 0) return { maxDate: '', maxDateNum: 0 }
    // 今日は更新してない場合
    if (!this.isToday(new Date(dateArray[0]))) {
      return { maxDate: '', maxDateNum: 0 }
    }
    dateArray.forEach((element) => {
      targetDate = new Date(element)
      if (afterDate == '') {
        // 最初の日付
        afterDate = targetDate
        return
      }
      //前回投稿日の前日を作成
      const checkedDate = new Date(afterDate.setDate(afterDate.getDate() - 1))
      if (checkedDate.getTime() == targetDate.getTime()) {
        //前回登校日とチェック中の登校日が連続した日付なら1足す
        countDateNum = countDateNum + 1
        if (maxDateNum < countDateNum) {
          maxDate = new Date(
            afterDate.setDate(afterDate.getDate())
          ).toLocaleDateString()
          maxDateNum = countDateNum
        }
      } else {
        //連続が途切れた場合
        if (maxDateNum < countDateNum) {
          // カウント中が最大値
          maxDate = new Date(
            afterDate.setDate(afterDate.getDate())
          ).toLocaleDateString()
          maxDateNum = countDateNum
        }

        return { maxDate, maxDateNum }
      }

      afterDate = targetDate
    })

    return { maxDate, maxDateNum }
  },
}
