<template>
    <v-overlay :value="this.$store.getters['loading']">
        <v-progress-circular
            indeterminate
            color="white"
    ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>