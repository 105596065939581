<template>
    <div v-if="view">
        <input
          type="file"
          accept="image/*"
          ref="input"
          @change="setImgData()"
        >
        <v-row v-if="fileUrls !== ''">
            <v-col col="6">
                <v-img
                :src="fileUrls"
                :heigt="400"
                :width="350"
                >
                </v-img>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            files: [],
            fileUrls:'',
            view:true
        }
    },
    methods: {
        setImgData(){
          if( this.$refs.input.files[0].type.match('image/*') == null){
              
              alert('異常なファイル形式です。指定できる拡張子 png/jpag/jpg/gif')
              this.view = false
              this.$nextTick(function () {
                this.view = true
              })
              
              return
          }
          this.files = []
          this.fileUrls = ''

          this.files.push(this.$refs.input.files[0])
          
          if(!this.files[0]){
            this.fileUrls  = ''
            return
          }
          this.fileUrls = window.URL.createObjectURL( this.files[0])
          this.$emit("getFile", this.files[0])
        },
    },
}
</script>