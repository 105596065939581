import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/Login'
import Logout from '@/pages/Logout'
import TopPage from '@/pages/Top'
import LoginComfirming from '@/pages/LoginComfirming'
import PostingContentsList from '@/pages/PostingContentsList'
import MyPostingContentsList from '@/pages/MyPostingContentsList'
import PostingForm from '@/pages/PostingForm'
import PostingFormUpdate from '@/pages/PostingFormUpdate'
import MyPage from '@/pages/MyPage'
import UserPage from '@/pages/UserPage'
import Setting from '@/pages/Setting'
import NotFound from '@/pages/NotFound'
import firebase from 'firebase'

import auth from '@/firebase/auth'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'TopPage',
      component: TopPage,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/postingForm',
      name: 'PostingForm',
      component: PostingForm,
      meta: { requiresAuth: true },
    },
    {
      path: '/postingForm/update/',
      name: 'PostingFormUpdate',
      component: PostingFormUpdate,
      meta: { requiresAuth: true },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/myPage',
      name: 'MyPage',
      component: MyPage,
      meta: { requiresAuth: true },
    },
    {
      path: '/myPostingContentsList',
      name: 'MyPostingContentsList',
      component: MyPostingContentsList,
      meta: { requiresAuth: true },
    },
    {
      path: '/_u/:uid',
      name: 'UserPage',
      component: UserPage,
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            // 認証済み
            if (to.params.uid == auth.getCurrentUser().uid) {
              //mypageへ
              return next({ path: '/myPage' })
            } else {
              return next()
            }
          } else {
            // 認証なし
            return next()
          }
        })
      },
    },
    {
      path: '/loginComfirming',
      name: 'LoginComfirming',
      component: LoginComfirming,
    },
    {
      path: '/_u/:uid/postingContentsList',
      name: 'PostingContentsList',
      component: PostingContentsList,
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            // 認証済み
            if (to.params.uid == auth.getCurrentUser().uid) {
              //mypageへ
              return next({ path: 'MyPostingContentsList' })
            } else {
              return next()
            }
          } else {
            // 認証なし
            return next()
          }
        })
      },
    },
    {
      path: '/setting',
      name: 'Setting',
      component: Setting,
      meta: { requiresAuth: true },
    },
    { path: '/*', component: NotFound },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // 認証済み
        return next()
      } else {
        // 認証なし ログイン画面に遷移
        return next({
          path: '/login',
        })
      }
    })
  } else {
    //認証の必要のないページ
    next()
  }
})

export default router
