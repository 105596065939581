import { firebase } from '@/firebase/init'
import firestore from '@/firebase/firestore'
import auth from '@/firebase/auth'
import calenderUtli from '../lib/calender'

const drawingContents = {
  namespaced: true,
  state: {
    drawingContentList: [],
    //日付ごとに
    drawingContentsListDateArray: {},
    maxUpdateDays: 0,
    updateDays: 0,
  },

  getters: {
    getDrawingContents(state) {
      return state.drawingContentList
    },
    getDrawingContentsById: (state) => (id) => {
      return state.drawingContentList.filter((a) => {
        return a.id == id
      })
    },
    getDrawingContentsByTagId: (state) => (id) => {
      return state.drawingContentList.filter((a) => {
        return a.tagId == id
      })
    },
    getDrawingContentsByDate: (state) => (date) => {
      return state.drawingContentList.filter((a) => {
        return a.createYmd == date
      })
    },
    getDrawingContentsDateArray(state) {
      return state.drawingContentsListDateArray
    },
    getMaxUpdateDays(state) {
      return state.maxUpdateDays
    },
    getUpdateDays(state) {
      return state.updateDays
    },
  },

  mutations: {
    setDrawingContents(state, payload) {
      firebase
        .storage()
        .ref()
        .child(payload.imagePath)
        .getDownloadURL()
        .then((imageUrl) => {
          state.drawingContentList.push({
            id: payload.id,
            uid: auth.getCurrentUser().uid,
            title: payload.title,
            tagId: payload.tagId,
            imageUrl: imageUrl,
            imagePath: payload.imagePath,
            caption: payload.caption,
            isPublished: payload.isPublished,
            updateAt: payload.updateAt,
            updateYmd: payload.updateYmd,
            createAt: payload.createAt,
            createYmd: payload.createYmd,
          })
        })
    },
    setDrawingContentsListByDate(state, payload) {
      if (
        !Array.isArray(state.drawingContentsListDateArray[payload.createYmd])
      ) {
        state.drawingContentsListDateArray[payload.createYmd] = []
      }
      state.drawingContentsListDateArray[payload.createYmd].push({
        id: payload.id,
        uid: auth.getCurrentUser().uid,
        createYmd: payload.createYmd,
      })
    },
    async deleteDrawingContents(state, payload) {
      let deleteItem = await state.drawingContentList.filter((a) => {
        return a.id == payload.id
      })

      let arrayContentDateUpdated =
        state.drawingContentsListDateArray[deleteItem[0].createYmd]

      let arrayContentDate = await arrayContentDateUpdated.filter((a) => {
        return a.id != payload.id
      })

      let arrContent = await state.drawingContentList.filter((a) => {
        return a.id != payload.id
      })

      state.drawingContentList = arrContent
      state.drawingContentsListDateArray = arrayContentDate
    },
    updateDrawingContents(state, payload) {
      const index = state.drawingContentList.findIndex(
        ({ id }) => id === payload.id
      )
      state.drawingContentList.splice(index, 1, payload)
    },
    clearDrawingContents(state) {
      state.drawingContentList
      state.drawingContentsListDateArray
      state.drawingContentList = []
      state.drawingContentsListDateArray = {}
    },
    setMaxUpdateDays(state) {
      let ret = calenderUtli.calcMaxUpdateDays(
        state.drawingContentsListDateArray
      )
      state.maxUpdateDays = ret.maxDateNum
    },
    setUpdateDays(state) {
      let ret = calenderUtli.calcUpdateDays(state.drawingContentsListDateArray)
      state.updateDays = ret.maxDateNum
    },
  },

  actions: {
    async getDrawingContent({ commit }) {
      await firestore
        .collection('drawingContents')
        .where('uid', '==', auth.getCurrentUser().uid)
        .get()
        .then((querySnapShot) => {
          querySnapShot.forEach(async (element) => {
            const data = element.data()
            commit('setDrawingContents', {
              id: element.id,
              uid: data.uid,
              title: data.title,
              tagId: data.tagId,
              imagePath: data.imageUrl,
              caption: data.caption,
              isPublished: data.isPublished,
              updateAt: data.updateAt,
              updateYmd: data.updateYmd,
              createAt: data.createAt,
              createYmd: data.createYmd,
            })

            commit('setDrawingContentsListByDate', {
              id: element.id,
              uid: data.uid,
              createYmd: data.createYmd,
            })
          })
        })
    },
    setDrawingContentsListByDate({ getters }) {
      firestore
        .collection('drawingContentsDate')
        .doc(auth.getCurrentUser().uid)
        .delete()
        .then(() => {
          firestore
            .collection('drawingContentsDate')
            .doc(auth.getCurrentUser().uid)
            .set({ calender: getters.getDrawingContentsDateArray })
        })
    },
    addDrawingContent({ commit, dispatch }, args) {
      firebase
        .storage()
        .ref()
        .child(args.postingItem.imageUrl)
        .getDownloadURL()
        .then((imageUrl) => {
          args.docRef
            .set({
              uid: auth.getCurrentUser().uid,
              title: args.postingItem.title,
              tagId: args.postingItem.tagId,
              imageUrl: args.postingItem.imageUrl,
              caption: args.postingItem.caption,
              isPublished: args.postingItem.isPublished,
              updateAt: args.postingItem.updateAt,
              updateYmd: args.postingItem.updateYmd,
              createAt: args.postingItem.createAt,
              createYmd: args.postingItem.createYmd,
            })
            .then(() => {
              commit('setDrawingContents', {
                id: args.docRef.id,
                uid: auth.getCurrentUser().uid,
                title: args.postingItem.title,
                tagId: args.postingItem.tagId,
                imageUrl: imageUrl,
                imagePath: args.postingItem.imageUrl,
                caption: args.postingItem.caption,
                isPublished: args.postingItem.isPublished,
                updateAt: args.postingItem.updateAt,
                updateYmd: args.postingItem.updateYmd,
                createAt: args.postingItem.createAt,
                createYmd: args.postingItem.createYmd,
              })
            })
            .then(() => {
              commit('setDrawingContentsListByDate', {
                id: args.docRef.id,
                uid: auth.getCurrentUser().uid,
                title: args.postingItem.title,
                tagId: args.postingItem.tagId,
                imageUrl: imageUrl,
                imagePath: args.postingItem.imageUrl,
                caption: args.postingItem.caption,
                isPublished: args.postingItem.isPublished,
                updateAt: args.postingItem.updateAt,
                updateYmd: args.postingItem.updateYmd,
                createAt: args.postingItem.createAt,
                createYmd: args.postingItem.createYmd,
              })
              commit('setMaxUpdateDays')
              commit('setUpdateDays')
            })
            .then(() => {
              dispatch('setDrawingContentsListByDate')
            })
        })
    },
    updateDrawingContentDate({ commit }, args) {
      firebase
        .storage()
        .ref()
        .child(args.postingItem.imagePath)
        .updateMetadata({
          customMetadata: {
            isPublished: args.postingItem.isPublished,
          },
        })
        .then(() => {
          firebase
            .storage()
            .ref()
            .child(args.postingItem.imagePath)
            .getDownloadURL()
            .then((imageUrl) => {
              args.docRef
                .update({
                  isPublished: args.postingItem.isPublished,
                  imageUrl: args.postingItem.imagePath,
                  updateYmd: args.postingItem.updateYmd,
                  updateAt: args.postingItem.updateAt,
                })
                .then(() => {
                  commit('updateDrawingContents', {
                    id: args.docRef.id,
                    uid: auth.getCurrentUser().uid,
                    title: args.postingItem.title,
                    tagId: args.postingItem.tagId,
                    imageUrl: imageUrl,
                    imagePath: args.postingItem.imagePath,
                    caption: args.postingItem.caption,
                    isPublished: args.postingItem.isPublished,
                    updateAt: args.postingItem.updateAt,
                    updateYmd: args.postingItem.updateYmd,
                    createAt: args.postingItem.createAt,
                    createYmd: args.postingItem.createYmd,
                  })
                })
            })
        })
    },
    updateDrawingContent({ commit }, args) {
      args.docRef
        .update({
          id: args.docRef.id,
          uid: auth.getCurrentUser().uid,
          title: args.updateItem.title,
          tagId: args.updateItem.tagId,
          imageUrl: args.updateItem.imagePath,
          caption: args.updateItem.caption,
          isPublished: args.updateItem.isPublished,
          updateAt: args.updateItem.updateAt,
          updateYmd: args.updateItem.updateYmd,
          createAt: args.updateItem.createAt,
          createYmd: args.updateItem.createYmd,
        })
        .then(() => {
          commit('updateDrawingContents', {
            id: args.docRef.id,
            uid: auth.getCurrentUser().uid,
            title: args.updateItem.title,
            tagId: args.updateItem.tagId,
            imageUrl: args.updateItem.imageUrl,
            imagePath: args.updateItem.imagePath,
            caption: args.updateItem.caption,
            isPublished: args.updateItem.isPublished,
            updateAt: args.updateItem.updateAt,
            updateYmd: args.updateItem.updateYmd,
            createAt: args.updateItem.createAt,
            createYmd: args.updateItem.createYmd,
          })
        })
    },
    updateDrawingContentDeleteTag({ commit }, args) {
      firestore
        .collection('drawingContents')
        .where('tagId', '==', args.id)
        .get()
        .then((querySnapShot) => {
          querySnapShot.forEach((element) => {
            const data = element.data()

            firebase
              .storage()
              .ref()
              .child(data.imageUrl)
              .getDownloadURL()
              .then((imageUrl) => {
                firestore.collection('drawingContents').doc(element.id).update({
                  tagId: 0,
                })

                commit('updateDrawingContents', {
                  id: element.id,
                  uid: data.uid,
                  title: data.title,
                  tagId: 0,
                  imageUrl: imageUrl,
                  imageimagePath: data.imageUrl,
                  caption: data.caption,
                  isPublished: data.isPublished,
                  updateAt: data.updateAt,
                  updateYmd: data.updateYmd,
                  createAt: data.createAt,
                  createYmd: data.createYmd,
                })
              })
          })
        })
    },
    deleteDrawingContent({ commit, dispatch }, args) {
      return new Promise((resolve, reject) => {
        let storageRef = firebase.storage().ref()
        let imageRef = storageRef.child(`img/${args.uid}/${args.id}`)

        // Delete the file
        imageRef
          .listAll()
          .then((res) => {
            res.items.forEach((itemRef) => {
              itemRef.delete()
            })
          })
          .then(() => {
            firestore.collection('drawingContents').doc(args.id).delete()
          })
          .then(async () => {
            commit('clearDrawingContents')
            dispatch('getDrawingContent')
            dispatch('setDrawingContentsListByDate')
            resolve()
          })
          .catch((error) => {
            console.error('Error removing document: ', error)
            reject()
          })
      })
    },
  },
}

export default drawingContents
