import Vue from 'vue'
import Vuex from 'vuex'

import users from './users'
import drawingContents from './drawingContents'
import tags from './tags'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    tags,
    drawingContents,
  },
  state: {
    user: {},
    userCredential: {},
    status: false,
    loginProgress: false,
    loading: false,
  },
  mutations: {
    onAuthStateChanged(state, user) {
      state.user = user
    },
    onloginProgressChanged(state, loginProgress) {
      state.loginProgress = loginProgress
    },
    onAuthCredentialChanged(state, userCredential) {
      state.userCredential = userCredential
    },
    onUserStatusChanged(state, status) {
      state.status = status
    },
    startLoading(state) {
      state.loading = true
    },
    endLoading(state) {
      state.loading = false
    },
  },
  getters: {
    userCredential: (state) => state.userCredential,
    user: (state) => state.user,
    loginProgress: (state) => state.loginProgress,
    isSignedIn: (state) => state.status,
    loading: (state) => state.loading,
  },
  actions: {},
})
