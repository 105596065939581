import firebase from '@firebase/app'
import '@firebase/auth'
import store from '../store'

export default {
  init() {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    firebase.analytics()
  },
  login() {
    const provider = new firebase.auth.TwitterAuthProvider()
    firebase.auth().signInWithRedirect(provider)
  },
  logout() {
    let ret = false
    firebase
      .auth()
      .signOut()
      .then((ret = true))
    return ret
  },
  onAuth() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        user = user ? user : {}
        store.commit('onAuthStateChanged', user)
        store.commit('onUserStatusChanged', user.uid ? true : false)
        if (user.uid) {
          await this.userDataInit()
          resolve()
        } else {
          //ログインしてない場合はstoreの初期化しない
          resolve()
        }
      })
    })
  },
  getCurrentUser() {
    return firebase.auth().currentUser
  },
  async userDataInit() {
    await store.dispatch('users/getUser')
    await store.dispatch('tags/getTagItem')
    await store.dispatch('drawingContents/getDrawingContent')
    await store.commit('drawingContents/setMaxUpdateDays')
    await store.commit('drawingContents/setUpdateDays')
  },
}
