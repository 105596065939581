<template>
  <v-app>
    <v-container >
      <v-row justify="center">
        <v-card style="max-width:640px">
          <v-card-text>
            404 File Not Found
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      appName : 'だれかき'
    }
  },  
}
</script>