import firebase from '@firebase/app'
import firestore from '@/firebase/firestore'

const users = {
  namespaced: true,
  state: {
    twitterName: '',
    twitterId: '',
    twitterIconURL: '',
  },

  getters: {
    twitterName: (state) => state.twitterName,
    twitterIconURL: (state) => state.twitterIconURL,
  },

  mutations: {
    setTwitterData(state, twitterData) {
      var twitterName = ''
      var twitterId = ''
      var twitterIconURL = ''
      if (typeof twitterData.profile != 'undefined') {
        twitterName = twitterData.profile.name
      } else {
        twitterName = twitterData.twitterName
      }

      if (typeof twitterData.username != 'undefined') {
        twitterId = twitterData.username
      } else {
        twitterId = twitterData.twitterName
      }

      if (typeof twitterData.profile != 'undefined') {
        twitterIconURL = twitterData.profile.profile_image_url
      } else {
        twitterIconURL = twitterData.twitterIconURL
      }

      state.twitterName = twitterName
      state.twitterId = twitterId
      state.twitterIconURL = twitterIconURL
    },
  },

  actions: {
    setTwitterData({ commit }, twitterData) {
      const usersRef = firestore
        .collection('users')
        .doc(firebase.auth().currentUser.uid)

      usersRef
        .set({
          twitterName: twitterData.profile.name,
          twitterId: twitterData.username,
          twitterIconURL: twitterData.profile.profile_image_url,
        })
        .then(() => {
          commit('setTwitterData', twitterData)
        })
    },
    getUser({ commit }) {
      const usersRef = firestore
        .collection('users')
        .doc(firebase.auth().currentUser.uid)

      usersRef
        .get()
        .then((doc) => {
          commit('setTwitterData', doc.data())
        })
        .catch(() => {})
    },
  },

  modules: {},
}

export default users
