<template>
  <v-card
      flat
      class="mx-auto"
  >
    <v-list-item >
    <v-list-item-content>
        <v-list-item-title class="text-h5">{{title}}</v-list-item-title>
        <v-list-item-subtitle>{{createdTime}}</v-list-item-subtitle>
    </v-list-item-content>
    </v-list-item>

    <v-img
    
      min-width="100%"
      min-height="100%"
      :src=imageUrl
    ></v-img>

    <v-card-text>
        {{caption}}
    </v-card-text>
    <v-card-actions>
      
      <v-spacer></v-spacer>
      
      <v-menu
        v-if="isMypage"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list >
          <v-list-item v-if="isPublished == 1">
            <v-btn text @click="switchPublished(id)">
              公開する
            </v-btn>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title>
              <v-btn text @click="switchPublished(id)">
                未公開にする
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title >
              <v-btn
                text
                color="red"
                @click="updateContents(id)"
              >
                編集
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title >
              <v-btn
                text
                color="red"
                @click="deleteContents(id)"
              >
                削除
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
import { firebase } from '@/firebase/init'

import firestore from '@/firebase/firestore'
import router from '@/router/' 

export default {
    props: ['id','caption','imageUrl','title','createdTime','isMypage','isPublished'],
    methods: {
      async deleteContents(id){
        await this.$store.dispatch('drawingContents/deleteDrawingContent',{id,uid:firebase.auth().currentUser.uid}).then(()=>{
          this.$store.commit('drawingContents/setMaxUpdateDays')
        })
      },
      updateContents(id){
        const updateContentListPath = `/postingForm/update`

        if(this.$route.path !== updateContentListPath ){
          router.push({
            path: updateContentListPath,
            query: {id}
          })
        }
      },
      switchPublished(id){
        const docRef = firestore.collection('drawingContents').doc(id)
        let postingItem = this.$store.getters['drawingContents/getDrawingContentsById'](id)[0]
        // 1なら0に 0なら1にする
        postingItem.isPublished = 1 - postingItem.isPublished
        this.$store.dispatch('drawingContents/updateDrawingContentDate', {postingItem,docRef})
      }
    },
}
</script>