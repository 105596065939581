<template>
  <v-app>
    <v-container >
      <v-row justify="center">
        <!-- <v-card style="max-width:640px; min-width:640px"> -->
          <v-container >
            <v-row class="mt-5 pt-5">
              <v-spacer></v-spacer>
              <v-card-title><h2>{{ appName }}</h2></v-card-title>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="mx-9 pt-9" justify="center" :height="346">
              <v-spacer></v-spacer>
              <v-col 
                cols="12" sm="12" md="4" lg="3" xl="3" 
              >  
                <h2>毎日描く。<br />描いた日を記録。<br/>「つづいた！」を見える形に、<br>
                モチベーションに。</h2>
                <br/>
                ・絵の練習結果を記録できるWEBサービスです。<br />
                ・投稿日、投稿数をカレンダーに色付きで記録！<br />
                
              </v-col>
              <v-col 
                cols="12" sm="12" md="4" lg="3" xl="3" 
                justify="center"
                style="display: table-cell vertical-align:center"
              >
                <v-img
                  style="margin:auto max-width:240px; min-width:240px;"
                  src="@/assets/img/top-image2.png" alt="Logo" class="header__logo"
                ></v-img>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="mx-9 pt-9" justify="center">
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6"
              >
                <h2>少しずつでも、練習でもOK。</h2><br />
                投稿は未完成の作品や落書き、クロッキーでもOK！<br />
                継続をカウントしてモチベーションUP
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row  justify="center" class="justify-center mb-0 pb-0">
              <v-col></v-col>
              <v-col class="centering mb-0 pb-0">
                <h5>Twitterアカウントで簡単登録！</h5>
                <br/>
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-row  justify="center" class="justify-center my-0 py-0">
              
              <v-col class="centering my-0 py-0" >
                <small>すべての機能を無料で利用できます</small>
                <br/>
              </v-col>
              
            </v-row>
            <v-row >
              <v-col></v-col>
              <v-col class="centering mt-0 pt-0">
                <v-btn to="login" color="cyan">登録する</v-btn>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-container>
        <!-- </v-card> -->
      </v-row>
    </v-container>
  </v-app>
</template>
<script src="https://adm.shinobi.jp/s/b05cda232f66c31180f4b127a20c4b82"></script>
<script>

export default {
  data() {
    return {
      appName : 'つづいったー'
    }
  },
}
</script>