<template>
  <div>
    <v-row
      v-if="!this.selected"
      justify="center"
    >
      <input
        style="display: none"
        ref="input"
        type="file"
        accept="image/*"
        @change="setImgData()"
      />
      <div
        @dragover.prevent
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
        
        color="deep-purple accent-4"
        :style="getStyle()"
        class="drop-area"
        :class="getClass()"
        @click="btnclick"
      >
        <div style=" text-align: center;">
          クリックして画像(jpeg/png)を選択 または ドラック＆ドロップ<br>
        </div>
      </div>
    </v-row>
    <v-row v-if="this.selected">
      <v-col col="6" >
        <v-img
          :heigt="imageHeight"
          :width="imageWidth"
          :src="fileUrls"
        >
          <v-btn class="ma-2 close-img-btn" icon v-on:click="closeImg">
              <v-icon class="centering" >
                mdi-close-circle-outline
              </v-icon>
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "DragAndDrop",
  props:['dropAreaHeight','dropAreaWidth','imageHeight','imageWidth'],
  data: () => ({
    files: '',
    selected:false,
    isDragging: false,

    dragCount: 0
  }),
  methods: {
    getStyle(){
      return {
        "height": this.dropAreaHeight,
        "width": this.dropAreaWidth,
      }
    },
    getClass(){
      return {
        ondrag : this.isDragging,
      }
    },
     btnclick() {
      this.$refs.input.click();
    },
    closeImg(){
      this.selected = false
      this.$emit("clearImg")
    },
    onDrop(e) {

      e.preventDefault()
      e.stopPropagation()
      this.isDragging = false

      //とりあえず単一選択だけ
      this.files = e.dataTransfer.files[0]
      this.fileUrls = window.URL.createObjectURL( this.files )
      this.selected = true
      this.$emit("getFile", this.files)

    },
    setImgData(){
      this.files = this.$refs.input.files[0]
      if( this.files.type.match('image/*') == null){
        alert('異常なファイル形式です。指定できる拡張子 png/jpag/jpg/gif')
        return
      }
      this.fileUrls = window.URL.createObjectURL( this.files )
      
      this.selected = true

      this.$emit("getFile", this.files)

    },
    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    }
  }
};
</script>

<style >
.label-center>.v-input__control>.v-input__slot>.v-text-field__slot>.theme--light.v-label{
	position  : absolute;
	top       : 50%;
	left      : 50%;
	transform : translate(25%, -50%);
}

.drop-area{
  color: gray;
   background-color:rgb(194, 196, 196);
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: dotted 3px;
}
.close-img-btn{
  background-color : rgba(212, 212, 212, 0.877); 
  z-index: 9999;
}

.ondrag{
  background-color:rgb(48, 77, 104);
}
</style>