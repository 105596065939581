<template>
  <div>
    <v-divider></v-divider>
    <v-container style="max-width:640px">
      <div style="height:20px"></div>
      <v-row class="pb-0">
        <v-col class="py-1" cols="9">
          <v-select
           hide-details
            v-model="id"
            item-text="tagName"
            item-value="id"
            :items="tagItems"
          />
        </v-col >
        <v-col class="py-1" cols="3">
          <v-select
            hide-details
            v-model="sortVal"
            :items="sortObj"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" ></v-col>
        <v-col class="py-0" >
          <v-btn v-if="id == 0" small elevation="0" color="#FFFFFF"><a style="color:grey;">このタグを編集する</a></v-btn>
          <TagUpdateDialog v-if="id != 0" :id="id" @clearSelectedTag='clearSelectedTag($event)'>
              <template v-slot:button>
                <v-btn  small elevation="0" color="#FFFFFF"><a>このタグを編集する</a></v-btn>
              </template>
          </TagUpdateDialog>
        </v-col>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col v-if="drawingContentList.length != 0">
          <div v-for="drawingContent in drawingContentList" :key="drawingContent.id" >
            <v-row style="display:block">
              <v-col>
                <DrawingConents
                  :isPublished="drawingContent.isPublished"
                  :id="drawingContent.id"
                  :caption="drawingContent.caption"
                  :imageUrl="drawingContent.imageUrl"
                  :title="drawingContent.title"
                  :createdTime="drawingContent.updateAt"
                  :isMypage="true"
                />
              </v-col>
              <v-divider></v-divider>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import DrawingConents from '@/components/DrawingContents.vue'
import TagUpdateDialog from '@/components/TagUpdateDialog.vue'


export default {
  name:"PostingContentsList",
  components:{
    DrawingConents,
    TagUpdateDialog
  },
  computed:{
    drawingContentList(){
      if(this.id == 0){
        return this.sortContents(this.$store.getters['drawingContents/getDrawingContents'])
      }
      return this.sortContents(this.$store.getters['drawingContents/getDrawingContentsByTagId'](this.id))
    },
    tagItems(){
      if( !this.$store.getters['tags/tagItems'].length ){
        return [{id:0,tagName:'指定なし'}]
      }else{
        return this.$store.getters['tags/tagItems']
      }
    }
  },
  methods:{
    clearSelectedTag(){
      this.id = 0
    },
    sortContents(list){
      if( this.sortVal == 0){
        return list.sort((a,b)=>{ 
          if (new Date (a.createAt) > new Date (b.createAt)) {
            return -1
          } else {
            return 1
          }
        })
      }

      if( this.sortVal == 1){
        return list.sort((a,b)=>{ 
          if (new Date(a.createAt) < new Date (b.createAt)) {
            return -1
          } else {
            return 1
          }
        })
      }
    }
  },
  data: () => ({
    id:0,
    sortVal:0,
    sortObj:[
      {
        value:0,
        text:'新しい順'
      },
      {
        value:1,
        text:'古い順'
      }
    ]
  }
),
}
</script>

<style scoped>
  .tab{
     border-radius: 5px !important; 
    border: 1px solid #9999DD !important;
  }
</style>