<template>
  <v-container>
    <v-card
      elevation="2"
      outlined  
      shaped
      tile
      class="mx-auto"
      max-width="344"
    >
     <v-card-text>
        <div>
          ログアウトしますか？<br />
          情報はTwitterアカウントに紐づいて保存されています。<br />
        </div>
     </v-card-text>
      <v-btn @click="logout" style="float: align-center">
        ログアウトする
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import Auth from '../firebase/auth'

export default {
  name:"logout",
  methods: {
      logout(){
          if( Auth.logout() ){
            this.$router.push('/')
          }
      }
  },
  data: () => ({
  }),
}
</script>
