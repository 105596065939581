import firestore from '@/firebase/firestore'
import auth from '@/firebase/auth'

const tags = {
  namespaced: true,
  state: {
    tagItems: [{ id: 0, tagName: '指定なし' }],
  },

  getters: {
    tagItems: (state) => {
      // 作成順で返す
      return state.tagItems.sort((a, b) => {
        if (a.createAt < b.createAt) {
          return -1
        } else {
          return 1
        }
      })
    },
    getTagItemById: (state) => (id) => {
      // 作成順で返す
      return state.tagItems.filter((a) => {
        return a.id == id
      })
    },
  },
  mutations: {
    setTags(state, payload) {
      state.tagItems.push({
        id: payload.id,
        uid: payload.uid,
        tagName: payload.tagName,
        createAt: payload.createAt,
        updateAt: payload.updateAt,
      })
    },
    updateTagItem(state, payload) {
      const index = state.tagItems.findIndex(({ id }) => id === payload.id)
      state.tagItems.splice(index, 1, payload)
    },
    deleteTagItem(state, payload) {
      let array = state.tagItems.filter((a) => {
        return a.id != payload.id
      })
      state.tagItems = array
    },
  },

  actions: {
    // firestoreから取得してVuexにcommit
    async getTagItem({ commit }) {
      await firestore
        .collection('tags')
        .where('uid', '==', auth.getCurrentUser().uid)
        .get()
        .then((querySnapShot) => {
          querySnapShot.forEach((element) => {
            const data = element.data()
            commit('setTags', {
              id: element.id,
              uid: data.uid,
              tagName: data.tagName,
              createAt: data.createAt,
              updateAt: data.updateAt,
            })
          })
        })
    },
    // firestoreから取得してVuexにcommit
    addTagItem({ commit }, args) {
      return new Promise((resolve, reject) => {
        firestore
          .collection('tags')
          .add({
            tagName: args.tagName,
            uid: auth.getCurrentUser().uid,
            updateAt: args.dateTime,
            createAt: args.dateTime,
          })
          .then((docRef) => {
            const id = docRef.id
            const uid = auth.getCurrentUser().uid
            const tagName = args.tagName
            const updateAt = args.dateTime
            const createAt = args.dateTime
            commit('setTags', { id, uid, tagName, updateAt, createAt })
            resolve(id)
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateTagItem({ commit }, args) {
      firestore
        .collection('tags')
        .doc(args.id)
        .update({
          tagName: args.tagName,
        })
        .then(() => {
          commit('updateTagItem', { id: args.id, tagName: args.tagName })
        })
    },
    deleteTagItem({ commit }, args) {
      firestore
        .collection('tags')
        .doc(args.id)
        .delete()
        .then(() => {
          commit('deleteTagItem', { id: args.id })
        })
    },
  },

  modules: {},
}

export default tags
