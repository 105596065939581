export default {
  isMobile() {
    const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    if (window.navigator.userAgent.search(regexp) !== -1) {
      return true
    } else {
      return false
    }
  },
}
