  <template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div  
          v-bind="attrs"
          v-on="on">
          <slot name="button">
            <v-btn class="px-0" style="height:100%;min-width:64px;">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </slot>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">タグ追加</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-form ref="tag_add_form">
                  <v-text-field
                    v-model="tagName"
                    label="タグ名"
                    :rules="[tag_length, tag_length_min]"
                    counter="30"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
          <small>作成したタグごとに作品数、制作時間を集計したりフィルタリング表示をすることができます。</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addTag"
          >
            作成
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  data: () => ({
    mode:0, /* 0->新規作成、 1->上書き */
    dialog: false,
    tagName: 'タグ名',
    tag_length: value => value.length <= 30 || "30文字以内で入力してください" ,// 文字数の制約
    tag_length_min: value => value.length >= 1 || "1文字以上入力してください" // 文字数の制約
  }),
  methods:{
    addTag(){

      if(!this.$refs.tag_add_form.validate()){
        return 
      }
      const tagName = this.tagName 
      const dateTime = new Date().toLocaleString()
      this.$store.dispatch('tags/addTagItem', {tagName, dateTime}).then((id) => {
        
        this.$emit("addTagSelectedItems",{id ,tagName})
        this.dialog = false
      })
    }
  }
}
</script>