<template>
  <v-app>
    <header-menu  />
    <div style="height:56px"></div>
    <router-view />
    <Footer />
    <Loading />
  </v-app>
</template>

<script>
import HeaderMenu from './components/HeaderMenu.vue'
import Footer from './components/Footer.vue'
import Auth from '@/firebase/auth'
import Loading from '@/components/Loading'


export default {
  name: 'App',
  created: async function (){
    this.$store.commit('startLoading')
    Auth.onAuth().then(() => {
      this.$store.commit('endLoading')
    })
  },
  components: {
    HeaderMenu,
    Footer,
    Loading
  },
  icons: {
    iconfont: 'mdiSvg',
  },
}
</script>

<style>
.centering{
  display: flex;   justify-content: center;  align-items: center;
  transform-origin: 50% 50%;
}
</style>