<template>
    <v-dialog
      v-model="display"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          更新完了！
        </v-card-title>

        <v-card-text v-if="updateNum == 1">
          今日の更新をシェアしませんか？
        </v-card-text>
        <v-card-text v-else>
          今日の更新をシェアしませんか？
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="tweet()"
          >
            ツイートする
          </v-btn>
          <v-btn
           @click="close()"
           >
              閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 </template>

<script>

import auth from '@/firebase/auth' 
import router from '@/router/'

export default {
    props: ['display','text'],
    data(){
        return {
            updateNum : this.$store.getters['drawingContents/getUpdateDays']
        }
    },
    methods:{
        tweet(){
            
           //シェアする画面を設定
            var shareURL = 'https://twitter.com/intent/tweet?text=' + this.text
           //シェア用の画面へ移行
            const option = 'status=1,width=600,height=200,top=100,left=100'
            window.open(shareURL, 'twitter', option)

        },
        close(){
            router.push({ path: `/_u/${auth.getCurrentUser().uid}` })
        }
    }
}
  
</script>