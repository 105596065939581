<template>
  <v-card class="overflow-hidden" style="position: fixed; z-index:1; width:3000px;" dense>
    <v-toolbar color="cyan" dark flat >
      <v-toolbar-title @click=toMain() >
        <a style="color:white">つづいったー</a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isLogin"
        class='mx-1'
        @click=toPostingForm()
      >
        投稿する!
      </v-btn> 

      <v-menu
        v-if="isLogin"
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }" >
          <v-btn icon v-on="on">
            <v-avatar>
              <v-img
                :src="img"
              ></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-btn
                depressed
                rounded
                text
                @click=toMypage()
              >
                マイページ
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click=toPostingList() style="text-decoration: none">
                投稿済み一覧
              </v-btn>
              <br>
              <!-- <v-btn
                depressed
                rounded
                text
                @click=toSetting() style="text-decoration: none"
              >
                設定
              </v-btn> -->
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click=toLogout()
              >
                ログアウト 
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
      <v-btn v-else color="cyan lighten-2" dark class='mx-1'>
        <router-link to="/login" style="text-decoration: none">
          ログイン/ユーザー登録
        </router-link>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import auth from '@/firebase/auth' 
import router from '@/router/' 

export default {
  name: 'HeaderMenu',
  methods:{
    toMypage(){
      const myPagePath = `/myPage`
      //mypageにいる場合は遷移しない
      if(this.$route.path !== myPagePath ){
        router.push({ path: myPagePath })
      } 
    },
    toPostingList(){
      const postingContentListPath = `/_u/${auth.getCurrentUser().uid}/postingContentsList`
      //postingContentListPath
      if(this.$route.path !== postingContentListPath ){
        router.push({ path: postingContentListPath })
      }
    },
    toSetting(){
        router.push({ path: '/setting' })
    },
    toLogout(){
      router.push({ path: '/logout'})
    },
    toPostingForm(){
      router.push({ path: '/postingForm'})
    },
    toMain(){
      if( this.isLogin ){
        router.push({ path: '/myPage'})
      }else{
        router.push({ path: '/'})

      }
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isSignedIn
    },
    img(){
        if ( this.$store.getters['users/twitterIconURL'] == "" ){
          return ""
        }else{
          return this.$store.getters['users/twitterIconURL'].replace("_normal", "")
        }
      },
  },
  data: () => ({
    word:'',
  }),
}
</script>
