<template>
  <div class="signup">
    <v-container>
      <v-row class="mx-1 my-1" justify="center">
        <v-card>
          <v-row>
            <v-card-text>
              Twitterアカウントを用いて気軽にログイン、ユーザー登録することができます。<br />
              つづいったーのアカウント名、アイコン画像はTwitterの設定がそのまま使用されます。<br />
            </v-card-text>
          </v-row>
          <v-row class="justify-center mt-5 pt-5">
              <small><TermOfServiceDialog></TermOfServiceDialog></small>
          </v-row>
          <v-row justify="center" >
            <v-btn class="mx-5 mb-5" to="loginComfirming" color="cyan">
              Twitterアカウントで登録/ログイン
            </v-btn>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TermOfServiceDialog from '@/components/TermOfServiceDialog.vue'
import router from '@/router/' 

export default {
  name: 'Login',
  data() {
    return {
      appName: 'だれかき',
      title: 'Login',
      btnLabel:'Twitterアカウントで登録/ログイン',
      loading: false,
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isSignedIn
    },
  },
  created() {
    if(this.isLogin){
       router.push({ path: '/myPage'})
    }
  },
  components:{
    TermOfServiceDialog
  }
}
</script>

