<template >
  <div  class="calender-border">
    <v-container class="calender" id="calender">
      <v-row justify="center">
        <v-col class="pb-0 px-0 centering" cols="1">
          <v-btn class="centering" @click="moveMonth(-1)" icon>
            <v-icon>
              {{leftBtn}}
            </v-icon>
          </v-btn>
        </v-col >
        <v-col cols="8" class="pb-0 px-0 centering">
          <div class="centering" style="font-weight: 600; display: flex; justify-content: center;">{{ _monthLabel }}</div>
        </v-col>
        <v-col class="pb-0 px-0 centering" cols="1">
          <v-btn @click="moveMonth(1)" class="centering" icon>
            <v-icon>
              {{rightBtn}}
            </v-icon>
          </v-btn>
        </v-col >
      </v-row>
      <v-row  justify="center">
        <v-spacer></v-spacer>
        <v-col class="day-col" v-for="(DAY_NAME, index) in DAY_NAME_LIST" :key="index">
          <div class="day-header" >{{DAY_NAME}}</div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row justify="center" v-for="(row, index) in _calendarList" :key="index">
        <v-spacer></v-spacer>
          <v-col v-for="(dateInfo, index2) in row" 
            class="day-col"
            :key="index2"
          >
            <div @click="setDate(dateInfo.date)" :id="_targetYm + dateInfo.day"  v-if="!dateInfo.isPrev && !dateInfo.isNext"
              class="day centering" :class="_dayClass(dateInfo)"
            >
              {{ dateInfo.day }}
            </div>
          <div v-else class="day-other-month centering">
          </div>
          </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-container class="calender-info">
      <v-row justify="center" class="py-0 ">
        <v-col class="py-2 pr-0 mr-0 d-flex">
          <div style="margin:auto 2px auto 0;">少</div>
          <div class="day-sample centering" style="margin:auto 0 auto 0;"></div>
          <div class="day-sample centering in-content-1" style="margin:auto 0 auto 0;"></div>
          <div class="day-sample centering in-content-2" style="margin:auto 0 auto 0;"></div>
          <div class="day-sample centering in-content-3" style="margin:auto 0 auto 0;"></div>
          <div style="margin:auto 0 auto 2px;">多</div>
        </v-col>
        <v-col class="pl-0 ml-0" cols="6"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import _ from 'underscore'

import { mdiArrowLeftBoldOutline } from '@mdi/js';
import { mdiArrowRightBoldOutline } from '@mdi/js';

import cUtil from '@/lib/calender.js'
import html2canvas from 'html2canvas'
import { firebase } from '@/firebase/init'
import firestore from '@/firebase/firestore'
import calenderUtil from '@/lib/calender.js'
export default {
  data() {
    return {
      DAY_NAME_LIST : ['日', '月', '火', '水', '木', '金', '土'],
      NOW : new Date(),
      offset: '0',
      selectedDate: null,
      targetMonth: new Date(),
      // icon
      leftBtn:mdiArrowLeftBoldOutline,
      rightBtn:mdiArrowRightBoldOutline
    }
  },
  props:['drawingNumAtDate'],
  computed: {
    _monthLabel() {
      const year = this.targetMonth.getFullYear();
      const month = this.targetMonth.getMonth();
      return `${year}年${month + 1}月`;
    },
    // カレンダーリストの算出
    _calendarList() {
      const year = this.targetMonth.getFullYear();
      const month = this.targetMonth.getMonth();
      return this.createCalendarList(year, month, this.offset);
    },
    _targetYm(){
      const year = this.targetMonth.getFullYear();
      const month = this.targetMonth.getMonth() + 1;
      return year + '/' + month + '/'
    },
  },
  methods: {
    _drawingNumAtDate(year, month , day){
      
      if(this.drawingNumAtDate){
        if ( this.drawingNumAtDate[cUtil.getYmd(new Date(year, month , day))] ){
          return this.drawingNumAtDate[cUtil.getYmd(new Date(year, month , day))]
        } else {
          return []
        }
      }
      return this.$store.getters['drawingContents/getDrawingContentsByDate']( cUtil.getYmd(new Date(year, month , day)))
    },
    getCalDate(date){
      return this.drawingNumAtDate.filter((a) => {
        return a.createYmd == date
      })
    },
    _dayClass(dateInfo){
      return {
        'other-month': dateInfo.isPrev || dateInfo.isNext,
        'selected': this.isSameDate(dateInfo.date, this.selectedDate),
        'now': this.isSameDate(dateInfo.date, this.NOW),
        'in-content-1': dateInfo.contents.length == 1,
        'in-content-2': dateInfo.contents.length == 2,
        'in-content-3': dateInfo.contents.length == 3,
        'in-content-4-more': dateInfo.contents.length >= 4
      }
    },
    createCalendarList(year, month, offset = 0) {

    // 前月の日を埋める
    const prevPaddingDays = (() => {
      const firstDay = new Date(year, month).getDay()
      // 埋める日数を求める（日曜日スタートがoffset:0で、月曜日からにする場合はoffset:1にする）
      const paddingDayCount = (firstDay + 7 - offset) % 7
      const prevLastDate = new Date(year, month, 0).getDate()
      return _.range(prevLastDate - paddingDayCount + 1, prevLastDate + 1).map(
        (day) => ({
          date: new Date(year, month - 1, day),
          day,
          ymd: cUtil.getYmd(new Date(year, month , day)),
          isPrev: true,
          isNext: false,
          contents: []
        })
      )
    })()

    // 今月の日にちリストを生成する
    const currentDays = (() => {
      const lastDate = new Date(year, month + 1, 0)
      const currentDayCount = lastDate.getDate()
      return _.range(1, currentDayCount + 1).map((day) => ({
        date: new Date(year, month, day),
        day,
        ymd: cUtil.getYmd(new Date(year, month , day)),
        isPrev: false,
        isNext: false,
        contents: this._drawingNumAtDate(year, month , day) 
      }))
    })()

    // 来月の日を埋める
    const nextPaddingDays = (() => {
      const paddingDayCount =
        (42 - (prevPaddingDays.length + currentDays.length)) % 7
      return _.range(1, paddingDayCount + 1).map((day) => ({
        date: new Date(year, month + 1, day),
        day,
        ymd: cUtil.getYmd(new Date(year, month , day)),
        isPrev: false,
        isNext: true,
        contents: []
      }))
    })()
    // 結合する
    const flatCalendar = [
      ...prevPaddingDays,
      ...currentDays,
      ...nextPaddingDays,
    ]
    // 2次元配列にして返す
    return _.range(0, flatCalendar.length / 7).map((i) => {
      return flatCalendar.slice(i * 7, (i + 1) * 7)
    })
  },
    setDate(day){
      this.$emit("clickDateCol", day);
    },

    /**
     * 月の移動
     * @param delta - 変化量
     */
    moveMonth(delta) {
      const year = this.targetMonth.getFullYear();
      const month = this.targetMonth.getMonth();
      this.targetMonth = new Date(year, month + delta);
    },
    isSameDate(date1, date2) {
      if (date1 == null || date2 == null) {
        return false
      }
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      )
    },
  },
updated() {
  this.$nextTick(function () {
            html2canvas(document.querySelector('#calender')).then((canvas) => {
          var calenderImg = canvas.toDataURL()
          const storageRef = firebase.storage().ref()
          var filePath = '/calender/' + firebase.auth().currentUser.uid + '/' + calenderUtil.getYm(new Date()) + '.png'
          storageRef
            .child(filePath)
            .putString(calenderImg,'data_url')
            .then( ( snapshot ) => {
              snapshot.ref.getDownloadURL().then((url)=>{
                const usersRef = firestore
                  .collection('users')
                  .doc(firebase.auth().currentUser.uid)

                usersRef.update({calenderUrl : url}).then(()=>{

                })
             })
            })
        })
  })
}
}
</script>


<style lang="scss">

.calender{
  width:260px; 
  min-height:300px;

  border: 1px solid #9999DD ;
  border-radius:9px ;
}

.calender-info{
  width:260px; 
}


.col>.day{
  font-weight: 500;
  width: 28px;
  height: 28px;
  border: 1px solid #9999DD ;
  border-radius:3px ;
  font-size: 14px;
   transition: all .0.5s ease-in-out;
}


.col>.day-sample{
  font-weight: 500;
  width: 14px;
  height: 14px;
  border: 1px solid #9999DD ;
  border-radius:3px ;
  font-size: 14px;
  transition: all .0.5s ease-in-out;
}

.col>.day:hover {
    box-shadow: 1px 1px 5px rgba(71, 71, 71, 0.65)
}

.day-other-month{
  width: 28px;
  height: 28px;
}

.other-month{
  color:#c2c1c1
}

.col>.day-header{
  width: 28px;
  height: 28px;
  font-size: 14px;

}

.now{
  background:linear-gradient(transparent 90%, #FF0000 0%);
}
.in-content-4-more{
  background-color:rgba(6, 48, 77, 0.856)
}

.in-content-3{
  background-color:rgba(30, 87, 126, 0.856)
}
.in-content-2{
  background-color:rgba(76, 156, 209, 0.856)
}

.in-content-1{
  background-color:rgba(174, 223, 255, 0.856)
}

.day-col{
  cursor: pointer;
  padding: 3px
}

</style>