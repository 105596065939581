<template>
  <v-footer class="py-0">
        <v-col
          cols="12" sm="12" md="12" lg="12" xl="12" 
          class="py-0 my-0 centering"
        >
            <v-breadcrumbs
            
            style="height:30px"
            divider="-"
            :items="items1"
            ></v-breadcrumbs>
            
        </v-col>
        <v-col
            cols="12" sm="12" md="12" lg="12" xl="12" 
            class="py-0 my-0"
        >
            <v-breadcrumbs
            class="py-0 my-0 centering"
            style="height:30px"
            divider="-"
            :items="items2"
            ></v-breadcrumbs>
            
        </v-col>
        <v-col
            class="text-center py-0 my-0"
            cols="12" sm="12" md="12" lg="12" xl="12" 
        >
        <small> Copyright ©  {{ new Date().getFullYear() }} — <strong>Tuzitter</strong> All Rights Reserved.</small>
        </v-col>
  </v-footer>
</template>
<script>
export default {
    name: 'Footer',
    props: ['text'],
    methods:{},
    data: () => ({
      items1: [
        {
          text: 'about',
          disabled: false,
          href: '/',
        },
        {
          text: 'Twitter',
          disabled: false,
          href: 'https://twitter.com/OekakiTuzitter',
        },

      ],
      items2: [
      {
          text: 'ご意見・ご要望',
          disabled: false,
          href: 'https://docs.google.com/forms/d/e/1FAIpQLSd1rgqmUXBqUgO5GszXDIqtL1cr7DmrrvH8xKw_zoP9DBqbJw/viewform?usp=sf_link',
        },
        {
          text: '不具合報告・お問い合わせ',
          disabled: false,
          href: 'https://docs.google.com/forms/d/e/1FAIpQLSdg6ovFcPb5V3CviIcJy8kRy1wIQAqvqYX93mK8kIbgXVd3UA/viewform?usp=sf_link',
        },
      ]
    }),
}
</script>