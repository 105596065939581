import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import router from './router'

import Auth from './firebase/auth'
Auth.init()

//ローカル用設定
// import { firebase } from '@/firebase/init'
// import firestore from '@/firebase/firestore'
// firestore.useEmulator('localhost', 8888)
// firebase.storage().useEmulator('localhost', 9199)
// firebase.functions().useEmulator('localhost', 5001)

import store from './store'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(router)
Vue.use(VueAxios, axios)
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing)
import { sync } from 'vuex-router-sync'
sync(store, router)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
