  <template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div  
          v-bind="attrs"
          v-on="on">
          <slot name="button">
            <v-btn class="px-0" style="height:100%;min-width:64px;">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </slot>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">タグ編集</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-form ref="tag_add_form">
                  <v-text-field
                    v-model="tagName"
                    label="タグ名"
                    :rules="[tag_length, tag_length_min]"
                    counter="30"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
          <small>作成したタグごとに作品数、制作時間を集計したりフィルタリング表示をすることができます。</small>
        </v-card-text>
        <v-card-actions>
          <div class="text-center">
            <v-dialog
              v-model="dialogComfirmDel"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  削除
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  削除確認
                </v-card-title>

                <v-card-text>
                  タグを削除してもよろしいですか？
                  このタグに振り分けられていた投稿は[指定なし]に割り振られます。
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="deleteTag()"
                  >
                    削除する
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogComfirmDel = false; dialog = false"
                  >
                    キャンセル
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="updateTag()"
          >
            更新
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  data: () => ({
    mode:0, /* 0->新規作成、 1->上書き */
    dialog: false,
    dialogComfirmDel:false,
    tag_length: value => value.length <= 30 || "30文字以内で入力してください" ,// 文字数の制約
    tag_length_min: value => value.length >= 1 || "1文字以上入力してください" // 文字数の制約
  }),
  props:['id'],
  methods:{
    deleteTag(){
      this.$store.dispatch('tags/deleteTagItem', {id:this.id}).then(() => {
        this.dialog = false
        this.dialogComfirmDel = false
        this.$store.dispatch('drawingContents/updateDrawingContentDeleteTag', {id:this.id})
      })
      this.$emit("clearSelectedTag")
    },
    updateTag(){
      this.$store.dispatch('tags/updateTagItem', {id:this.id,tagName:this.tagName}).then(() => {
        this.$emit("addTagSelectedItems",{id: this.id ,tagName : this.tagName})
        this.dialog = false
        this.dialogComfirmDel = false
      })
    }
  },
  computed:{
    tagName(){
      return this.$store.getters['tags/getTagItemById'](this.id)[0].tagName
    }
  }
}
</script>