<template>
  <v-form ref="posting_form">
    <v-container class="posting-form" style="max-width:640px">
      <v-row v-if="!selectedImage" justify="center" class="pb-6">
        <v-img :src="drawingContents.imageUrl"/>
      </v-row>
      <v-row v-if="!selectedImage" justify="center">          
        <a @click="fileClear">絵を更新する</a>
      </v-row>
      <v-row v-else justify="center" class="pb-6">
        <v-row justify="center" v-if="!isMobile()" class="pb-6">
          <FileUpload :dropAreaHeight="dropAreaHeight" :dropAreaWidth="dropAreaWidth" :imageHeight="imageHeight" :imageWidth="imageWidth" @getFile='uploadFile = $event'/>
        </v-row>
        <v-row justify="center" v-else>
          <FileUploadMobile @getFile='uploadFile = $event'/>
        </v-row>
      </v-row>
      <small v-if="sendImgEmpty" style="color:red;">画像を選択してください</small>
      <div style="height:30px;"/>
      <v-row class="mt-3">
        <v-col style="height:70px;">
          <v-text-field 
            label="タイトル"
            :rules="[limit_length_title,limit_length_title_min]"
            counter="30"
            v-model="drawingContents.title"
          >
          </v-text-field>
        </v-col>
      </v-row>
        <v-row >
          <v-col style="height:70px;">
            <v-select
              label="タグ"    
              v-model="tagSelectedItems"
              @change="fetchTagVal"
              :items="this.$store.getters['tags/tagItems']"
              item-text="tagName" item-value="id"
              outlined
              persistent-hint
              counter="200"
            ></v-select>
          </v-col>
        </v-row> 
        <v-row class="py-0" justify="end">
          <v-spacer></v-spacer>
          <v-col class="py-0" cols="4" xs="3" sm="3">
            <TagAddDialog @addTagSelectedItems="addTagSelectedItems($event)">
              <template v-slot:button>
                <v-btn>
                    <div>タグを追加...</div>
                </v-btn>
              </template>
            </TagAddDialog>
          </v-col>
        </v-row>
        <v-row >
          <v-col style="height:150px;">
            <v-textarea
              v-model="drawingContents.caption"
              label="キャプション"
              no-resize
              class="mix-height:30px"
              outlined
              persistent-hint
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row  class="py-0">
            <v-col class="py-0 col-header" style="height:70px;" cols="3">
                <p style="text-align: center;">公開設定</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col  class="py-0" style="height:70px;">
                <v-radio-group v-model="drawingContents.isPublished" :column="false" cols="3">
                    <v-radio class="right-input" label="公開" />
                    <v-radio class="right-input" label="非公開" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row class="mt-9">
            <v-btn color="cyan lighten-2" block @click="updateContent">
                投稿する！
            </v-btn>
        </v-row>
    </v-container>
  </v-form>
</template>

<script>
import calenderUtil from '@/lib/calender.js'

import agentUtil from '@/lib/agentUtil.js'
import TagAddDialog from '@/components/TagAddDialog.vue'

import firestore from '@/firebase/firestore'
import { firebase } from '@/firebase/init'
import router from '@/router/' 

import FileUpload from '@/components/FileUpload.vue'
import FileUploadMobile from '@/components/FileUploadMobile.vue'

export default {
  name:"PostingForm",
  data: () => ({
    sendImgEmpty: false,
    uploadFile: '',
    title:'無題',
    caption:'',
    expand: false,
    label:'タグを追加...',
    profuctionTimeInputflg:false,
    productionHours:0,
    productionMinutes:0,
    modifyImgFlg: true,
    isPublished: 0,
    tagId:0,
    dropAreaHeight: '300px',
    dropAreaWidth:'600px',
    imageHeight:'200px',
    imageWidth:'500px',
    tagItems:[],
    limit_length_title: value => value.length <= 30 || "30文字以内で入力してください", // 文字数の制約
    limit_length_title_min: value => value.length > 0 || "1文字以上で入力してください", // 文字数の制約
    limit_length_caption: value => value.length <= 200 || "200文字以内で入力してください" // 文字数の制約
  }),
  computed:{
    drawingContentsId(){
      return this.$route.query.id
    },
    drawingContents(){
      let item = this.$store.getters['drawingContents/getDrawingContentsById'](this.drawingContentsId)[0]

      if(!item){
        return {
          id: '',
          uid: '',
          title: '',
          tagId: '',
          imageUrl: '',
          caption: '',
          isPublished: '',
          updateAt: '',
          updateYmd: '',
          createAt: '',
          createYmd: '',
        }
      }else{
        return item
      }
    },
    selectedImage(){
      let item = this.$store.getters['drawingContents/getDrawingContentsById'](this.drawingContentsId)[0]
      if(!item){
        return false
      }
      return !item.imageUrl
    },

    tagSelectedItems:{
       get () {
        let item = this.$store.getters['tags/getTagItemById'](this.drawingContents.tagId)
        if( this.drawingContents.tagId == 0){
          return {
            id: 0,
            tagName: '指定なし'
          }
        }
        return item[0]
      },
      set(e){
        return e;
      }
    },
  },
  methods: {
    fileClear(){
        this.drawingContents.imageUrl = ""
        this.uploadFile = ''
    },
    isMobile(){
      return agentUtil.isMobile()
    },
    fetchTagVal(e){
      this.tagId = e
    },
    addTagSelectedItems(e){
      this.tagSelectedItems = e;
      this.drawingContents.tagId  = e.id
      this.tagId  = e.id
    },
    getTag(){
        this.tagItems = this.$store.getters['tags/tagItems']
        this.tagItems.unshift({id: 0, tagName: '指定なし'})
    },
    updateExec(updateItem){
      const docRef = firestore.collection('drawingContents').doc(updateItem.id)
      this.$store.dispatch('drawingContents/updateDrawingContent', {updateItem,docRef}).then(()=>{
        this.$store.commit('endLoading')
        router.push({ path: '/myPage' })
      })
    },
    updateContent(){
      //開始

            
      if(this.uploadFile == '' && !this.drawingContents.imageUrl){
        this.sendImgEmpty = true
        return 
      }

      this.$store.commit('startLoading')
      const ymd = calenderUtil.getYmd()
      const dateTime = calenderUtil.getDateTime()
      
      let updateItem = this.drawingContents
      updateItem.tagId = this.tagId
      
      updateItem.updateYmd = ymd
      updateItem.updateAt = dateTime

      if( !this.profuctionTimeInputflg ){
        updateItem.productionHours = 0
        updateItem.productionMinutes = 0
      }
      // 画像のアップロード
      const storageRef = firebase.storage().ref()

      if(this.uploadFile === '' ){
        // 画像更新なし
        this.updateExec(updateItem)
      } else {
        // 画像更新あり
        const filePath = '/img/' + firebase.auth().currentUser.uid + '/' + updateItem.id + '/' + 0
  
        storageRef
        .child( filePath )
        .put( this.uploadFile )
        .then( ( snapshot ) => {

          snapshot.ref.updateMetadata(
            {
              customMetadata: {
                'isPublished': updateItem.isPublished,
              }
            })
          snapshot.ref.getDownloadURL().then( (url) => {
            // 画像アップロードに成功したらfirestoreに記録
            updateItem.imageUrl = url
            updateItem.imagePath = filePath
            this.updateExec(updateItem)
          })
        }) 
        
      }
    }
  },
  components:{
    TagAddDialog,
    FileUpload,
    FileUploadMobile
  },
}
</script>

<style >
.right-input input {
    text-align: right;
}

.posting-form>.row{
    justify-content: center;
}

.col>p{
  margin-bottom: 0px;
}

.col-header{
    display: flex;  flex-direction: column;  justify-content: center;  align-items: center;
}
</style>